const guestRoutes = [
    {
        path: '/guest-patient',
        name: 'guestPatient',
        children: [
            {
                path: '',
                name: 'guestPatientList',
                component: () => import('@/modules/guest_patient/list/List.vue'),
                meta: {
                    resource: 'patients',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Guest Patients',
                    breadcrumbs: [{ title: 'Guest Patient List', routeName: 'guestPatientList' }]
                }
            }
        ]
    }
]

export default guestRoutes
