<template>
    <div class="card">
        <div class="flex flex-wrap justify-content-between align-items-center">
            <h5 class="m-0 md:mb-2 block lg:hidden">Edit Content</h5>
        </div>
        <form class="p-fluid" @submit.prevent="handleSubmit()">
            <div class="grid">
                <div class="col-12 md:col-12 lg:col-12">
                    <label for="title" :class="{ 'p-error': formError.titleEn && submitted }" class="font-bold">{{ $t('Title (English)') }}<span class="p-error">*</span></label>
                    <InputText id="title" v-model="state.titleEn" placeholder="Content title" class="w-full mt-2" />
                    <small v-if="formError.titleEn" class="p-error">
                        <div>
                            {{ formError.titleEn }}
                        </div>
                    </small>
                </div>
                <div class="field col-12 md:col-12 lg:col-12">
                    <label for="entries" class="font-bold">Content (English)</label>
                    <Editor v-model="state.contentEn" editorStyle="height: 200px"></Editor>
                    <small v-if="errors.has('content')" class="p-error">
                        <div v-for="error in errors.get('content')" :key="error">
                            {{ error }}
                        </div>
                    </small>
                </div>
                <div class="col-12 md:col-12 lg:col-12">
                    <label for="title" :class="{ 'p-error': formError.titleMm && submitted }" class="font-bold">{{ $t('Title (Myanmar)') }}<span class="p-error">*</span></label>
                    <InputText id="title" v-model="state.titleMm" placeholder="Content title" class="w-full mt-2" />
                    <small v-if="formError.titleMm" class="p-error">
                        <div>
                            {{ formError.titleMm }}
                        </div>
                    </small>
                </div>
                <div class="field col-12 md:col-12 lg:col-12">
                    <label for="entries" class="font-bold">Content (Myanmar)</label>
                    <Editor v-model="state.contentMm" editorStyle="height: 200px"></Editor>
                    <small v-if="errors.has('content')" class="p-error">
                        <div v-for="error in errors.get('content')" :key="error">
                            {{ error }}
                        </div>
                    </small>
                </div>

                <div class="field col-12 md:col-6 lg:col-6">
                    <label class="font-bold" for="category_id" :class="{ 'p-error': v$.category_id.$invalid && submitted }">{{ $t('Category') }}<span class="p-error">*</span> </label>
                    <Dropdown id="id" v-model="state.category_id" :options="optionsCategory" optionLabel="name" optionValue="id" placeholder="Select category" class="w-full" />
                    <small v-if="errors.has('category_id')" class="p-error">
                        <div v-for="error in errors.get('category_id')" :key="error">
                            {{ error }}
                        </div>
                    </small>
                </div>
                <div class="field col-12 md:col-6 lg:col-6">
                    <label class="font-bold"> Published At </label>
                    <Calendar class="w-full" :showIcon="true" dateFormat="yy-mm-dd" :minDate="new Date()" v-model="state.published_at" placeholder="Choose published Date" />
                </div>
                <div class="field col-12 md:col-6">
                    <label for="user_type" class="font-bold">User Type</label>
                    <div class="grid mt-2 ml-2">
                        <div class="flex field-checkbox pr-4" v-for="user of optionsUserType" :key="user.code">
                            <RadioButton :inputId="user.code" name="user" :value="user.code" v-model="v$.user_type.$model" />
                            <label :for="user.code">{{ user.name }}</label>
                        </div>

                        <!-- Server Validation -->
                        <small v-if="errors.has('type')" class="p-error">
                            <div v-for="error in errors.get('type')" :key="error">
                                {{ error }}
                            </div>
                        </small>
                        <!-- Server Validation -->
                    </div>
                </div>

                <div class="field col-12 md:col-6 lg:col-4">
                    <label for="post_type" class="font-bold">Post Type</label>
                    <div class="grid mt-2 ml-2">
                        <div class="flex field-radiobutton pr-4" v-for="post of optionsPostType" :key="post.code">
                            <RadioButton :inputId="post.code" name="post" :value="post.code" v-model="v$.post_type.$model" />
                            <label :for="post.code">{{ post.name }}</label>
                        </div>
                    </div>
                </div>

                <div class="field col-12 md:col-6 lg:col-6">
                    <label class="font-bold" :class="{ 'p-error': v$.expired_at.$invalid && submitted }"> Expired At </label>
                    <Calendar class="w-full" :showIcon="true" dateFormat="yy-mm-dd" :minDate="new Date(Date.now() + 1 * 86400000)" v-model="state.expired_at" placeholder="Choose published Date" />
                </div>

                <div class="field col-12 md:col-3 lg:col-3 flex flex-row align-items-center h-7rem">
                    <Checkbox id="is_notifiable" class="mr-3" inputId="binary" :binary="true" v-model="state.is_notifiable" />
                    <label class="font-bold mt-2" for="is_notifiable"> Is Notifiable </label>
                </div>

                <div class="col-6 md:col-6 lg:col-6">
                    <label for="title" class="font-bold">{{ $t('Video Url') }}</label>
                    <InputText id="url" v-model="state.video_url" placeholder="Video Url" class="w-full mt-2" />
                </div>

                <div class="col-6 md:col-6 lg:col-6"></div>

                <div class="field col-12 md:col-6 lg:col-6">
                    <label for="photo" class="font-bold">{{ $t('photo') }}<span class="p-error">*</span></label>
                    <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" :chooseLabel="photoURL ? 'Change' : 'Browse'" :customUpload="true" :auto="true" uploadIcon="ri-upload-2-fill" @uploader="onUpload($event)" />
                    <div class="field mt-3">
                        <Image :src="photoURL" alt="Image" width="200" height="200" preview />
                    </div>
                    <span v-if="imgErrorMessage" class="error">{{ imgErrorMessage }}</span>
                </div>
            </div>
            <div class="col-12 md:col-12 flex justify-content-start">
                <div class="flex pr-4" v-if="$can('create', 'health-article') && state.status == `DRAFTED`">
                    <Button type="submit" label="Update " @click="onClickDraft()" class="mt-2 p-button-warning p-button-filled" />
                </div>
                <div class="flex pr-4" v-if="$can('publish', 'health-article')">
                    <Button type="submit" label="Update & Publish" @click="onClickPublish()" class="mt-2 p-button-warning p-button-outlined" />
                </div>
                <div class="flex pr-4" v-if="state.status == `PUBLISHED` && $can('disable', 'health-article')">
                    <Button label="Unpublished" @click="onClickUnpublish()" class="mt-2 mr-10 p-button-danger p-button-outlined" />
                </div>
                <div class="flex pr-4">
                    <router-link v-if="$can('view', 'health-article')" :to="{ name: 'healthArticleList' }">
                        <div>
                            <Button :label="$t('cancel')" class="mt-2 p-button-outlined p-button-secondary" />
                        </div>
                    </router-link>
                </div>
            </div>
        </form>

        <Dialog header="Crop Image" v-model:visible="showCropDialog" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '50vw' }">
            <div class="p-4">
                <cropper
                    class="cropper"
                    v-if="photo"
                    :src="photo"
                    :stencil-props="{
                        aspectRatio: 1 / 1
                    }"
                    @change="cropImage"
                ></cropper>
            </div>
            <template #footer>
                <Button label="Got it" @click="showCropDialog = false" autofocus />
            </template>
        </Dialog>

        <ConfirmDialog :showHeader="false">
            <template #message="slotProps">
                <div class="text-center w-full">
                    <Button type="button" :icon="slotProps.message.icon" class="p-button-lg p-button-danger p-button-rounded p-button-outlined mt-4" />
                    <h4>{{ slotProps.message.message }}</h4>
                </div>
            </template>
        </ConfirmDialog>

        <Loading v-if="isLoading"></Loading>
    </div>
</template>

<script>
// import BlockUI from "primevue/blockui";
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import FileUpload from 'primevue/fileupload'
import Loading from '@/components/Loading.vue'
import Dialog from 'primevue/dialog'
import Dropdown from 'primevue/dropdown'
import Editor from 'primevue/editor'
import ConfirmDialog from 'primevue/confirmdialog'
import { Cropper } from 'vue-advanced-cropper'
import { defineComponent } from 'vue'
import Checkbox from 'primevue/checkbox'
import Calendar from 'primevue/calendar'
import Image from '@/components/Image'
import RadioButton from 'primevue/radiobutton'
import { useEditHealthArticle } from '@/modules/health_article/entry/useEdit'
// import Quill from "quill";

export default defineComponent({
    name: 'EditContent',
    components: {
        Loading,
        Calendar,
        RadioButton,
        FileUpload,
        Image,
        Button,
        InputText,
        Dialog,
        Checkbox,
        Cropper,
        Dropdown,
        Editor,
        ConfirmDialog
    },
    setup() {
        const {
            formError,
            isLoading,
            state,
            Image,
            RadioButton,
            FileUpload,
            submitted,
            optionsPostType,
            handleSubmit,
            optionsUserType,
            errors,
            optionsStatus,
            optionsCategory,
            showConfirmDialog,
            crop_photo,
            cropImage,
            showCropDialog,
            takePicture,
            onClickUnpublish,
            onClickPublish,
            photoURL,
            onUpload,
            photo,
            v$,
            onClickDraft,
            imgErrorMessage
        } = useEditHealthArticle()

        return {
            formError,
            isLoading,
            optionsUserType,
            state,
            optionsPostType,
            RadioButton,
            FileUpload,
            Image,
            onClickUnpublish,
            onClickPublish,
            submitted,
            onUpload,
            handleSubmit,
            errors,
            photoURL,
            optionsStatus,
            optionsCategory,
            showConfirmDialog,
            crop_photo,
            cropImage,
            showCropDialog,
            takePicture,
            photo,
            v$,
            onClickDraft,
            imgErrorMessage
        }
    }
})
</script>
<style lang="scss" scoped>
.card-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.error {
    color: red;
}
</style>
