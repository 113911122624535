const moduleRoutes = [
    {
        path: '/coupon',
        name: 'coupons',
        children: [
            {
                path: '',
                name: 'couponList',
                component: () => import('@/modules/coupon/list/List.vue'),
                meta: {
                    resource: 'coupons',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Coupons',
                    breadcrumbs: [{ title: 'Coupon List', routeName: 'couponList' }]
                }
            },
            {
                path: 'new',
                name: 'couponNew',
                component: () => import('@/modules/coupon/entry/New.vue'),
                meta: {
                    resource: 'coupons',
                    action: 'create',
                    layout: 'default',
                    auth: true,
                    title: 'New Coupon',
                    breadcrumbs: [
                        { title: 'Coupon List', routeName: 'couponList' },
                        { title: 'New', routeName: 'couponNew' }
                    ]
                }
            },
            {
                path: ':id/edit',
                name: 'couponEdit',
                component: () => import('@/modules/coupon/entry/Edit.vue'),
                meta: {
                    resource: 'coupons',
                    action: 'view_detail',
                    layout: 'default',
                    auth: true,
                    title: 'Edit Coupon',
                    breadcrumbs: [
                        { title: 'Coupon List', routeName: 'couponList' },
                        { title: 'Edit', routeName: 'couponEdit' }
                    ]
                }
            },
            {
                path: ':id/detail',
                name: 'couponDetail',
                component: () => import('@/modules/coupon/detail/Detail.vue'),
                meta: {
                    resource: 'coupons',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Coupon Detail'
                    // breadcrumbs: [
                    //   { title: "Coupon List", routeName: "couponList" },
                    //   { title: "New", routeName: "couponNew" }
                    // ]
                }
            }
        ]
    },
    {
        path: '/couponGift',
        name: 'couponGift',
        children: [
            {
                path: '',
                name: 'giftCouponList',
                component: () => import('@/modules/coupon/gift_coupon_list/List.vue'),
                meta: {
                    resource: 'coupons',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Gift Coupons',
                    breadcrumbs: [{ title: 'Gift Coupon List', routeName: 'giftCouponList' }]
                }
            },
            {
                path: '/couponGift/new',
                name: 'giftCouponNew',
                component: () => import('@/modules/coupon/gift_coupon_entry/New.vue'),
                meta: {
                    resource: 'coupons',
                    action: 'create',
                    layout: 'default',
                    auth: true,
                    title: 'New Gift Coupon',
                    breadcrumbs: [
                        { title: 'Gift Coupon List', routeName: 'giftCouponList' },
                        { title: 'New', routeName: 'giftCouponNew' }
                    ]
                }
            },
            {
                path: '/:id/couponGiftEdit',
                name: 'couponGiftEdit',
                component: () => import('@/modules/coupon/gift_coupon_entry/Edit.vue'),
                meta: {
                    resource: 'coupons',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Edit Gift Coupon',
                    breadcrumbs: [
                        { title: 'Gift Coupon List', routeName: 'giftCouponList' },
                        { title: 'Edit', routeName: 'couponGiftEdit' }
                    ]
                }
            },
            {
                path: '/:id/couponGiftList',
                name: 'giftList',
                component: () => import('@/modules/coupon/gift_coupon_list/GiftList.vue'),
                meta: {
                    resource: 'coupons',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Welcome Gift List',
                    breadcrumbs: [
                        { title: 'Gift Coupon List', routeName: 'giftCouponList' },
                        { title: 'Gift Coupon Detail List', routeName: 'giftList' }
                    ]
                }
            }
        ]
    }
]

export default moduleRoutes
