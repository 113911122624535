import HealthArticle from '@/modules/health_article/list/List'
import NewHealthArticle from '@/modules/health_article/entry/New'
import EditHealthArticle from '@/modules/health_article/entry/Edit'

const routes = [
    {
        path: '/health-article',
        name: 'healthArticle',
        children: [
            {
                path: '',
                name: 'healthArticleList',
                component: HealthArticle,
                meta: {
                    layout: 'default',
                    action: 'view',
                    resource: 'health-article',
                    title: 'Health Articles',
                    breadcrumbs: [{ title: 'Health Articles', routeName: 'healthArticleList' }]
                }
            },
            {
                path: 'new',
                name: 'newHealthArticle',
                component: NewHealthArticle,
                meta: {
                    layout: 'default',
                    action: 'create',
                    resource: 'health-article',
                    title: 'Create Health Article',
                    breadcrumbs: [
                        { title: 'Health Articles', routeName: 'healthArticleList' },
                        { title: 'Create', routeName: 'newHealthArticle' }
                    ]
                }
            },
            {
                path: ':id/edit',
                name: 'editHealthArticle',
                component: EditHealthArticle,
                meta: {
                    layout: 'default',
                    action: 'update',
                    resource: 'health-article',
                    title: 'Edit Health Article',
                    breadcrumbs: [
                        { title: 'Health Articles', routeName: 'healthArticleList' },
                        { title: 'Edit', routeName: 'editHealthArticle' }
                    ]
                }
            }
        ]
    }
]

export default routes
