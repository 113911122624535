import api from '@/libs/axios'
import qs from 'qs'

const endpoints = {
    content_list: `/content/list`,
    content_add: `/content/create`,
    content_update: `/content/update`,
    content_show: `/content`
}

const useHealthArticleService = {
    fetchAllContent: async (params) => {
        try {
            const result = await api.get(`${endpoints.content_list}`, {
                params: params,
                paramsSerializer: (params) => {
                    return qs.stringify(params)
                }
            })

            return result.data
        } catch {
            return null
        }
    },

    fechAddContent: async (params) => {
        const headers = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        const result = await api.post(`${endpoints.content_add}`, params, headers)
        console.log(result.data)
        return result.data
    },

    fetchEditContent: async (params) => {
        try {
            const result = await api.get(`${endpoints.content_show}/${params.id}`)
            return result.data
        } catch {
            return null
        }
    },

    fetchUpdateContent: async (params) => {
        const headers = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        const result = await api.post(`${endpoints.content_update}`, params, headers)
        return result.data
    },

    fetchDeleteContent: async (params) => {
        try {
            const result = await api.delete(`${endpoints.cms}/${params.id}`)
            return result.data
        } catch {
            return null
        }
    }
}

export { useHealthArticleService }
