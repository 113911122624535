import { ref, computed, watch, onMounted } from 'vue'
import { useStore } from './store'
import { useAuthStore } from '../auth/authStore'
import { useDebounceFn } from '@/utils/debounce'
import { db } from '@/libs/firebase'
import { formatChatListTime } from '@/utils/formatter'
import { useRouter, useRoute } from 'vue-router'

export default function useInstantChat() {
    const showSideBar = ref(false)
    // const supportChatUser = ref([])
    const store = useStore()
    const authStore = useAuthStore()
    const router = useRouter()
    const route = useRoute()
    const user = authStore.getUserData

    const appointmentChatUser = ref([])

    const chatId = ref('')
    const chatUserName = ref('')
    const chatCollectionName = ref('teleconsultation_chats')
    const chatImageUrl = ref('')
    const chatMateUserId = ref('')
    const isEmpty = ref(true)
    const activeIndex = ref(0)
    const isLoading = ref(false)

    const search = ref('')
    const currentPage = ref(0)
    const isLastPage = ref(false)
    const activate = ref(false)

    onMounted(() => {
        if (user.role != 'doctor' && route.name == 'appointmentChat') {
            router.replace({ name: 'dashboard' })
        }
        appointmentChatUser.value = []
        if (user !== 'undefined' && user.role == 'doctor') {
            fetchAppointmentChatUser()
            fetchAppointmentChatUser()
            fetchAppointmentChatUser()
        }
    })

    const chooseConversation = (user) => {
        isEmpty.value = false
        chatId.value = user.id.toString()
        chatUserName.value = user.patient_name
        chatImageUrl.value = user.patient_avatar
        chatMateUserId.value = user.patient.user_id.toString()
        activate.value = user.activate
    }

    const getUserList = computed(() => {
        // if (activeIndex.value === 0) return supportChatUser.value
        // else if (activeIndex.value === 1) return appointmentChatUser.value
        // else return []

        appointmentChatUser.value.sort(function (a, b) {
            let x = a.last_message ? a.last_message.time.toDate() : null
            let y = b.last_message ? b.last_message.time.toDate() : null
            if (x < y) {
                return 1
            }
            if (x > y) {
                return -1
            }
            return 0
        })

        return appointmentChatUser.value
    })

    const fetchAppointmentChatUser = async () => {
        if (!isLastPage.value) {
            // isLoading.value = true
            currentPage.value++

            //fetch API
            await store.fetchAppointmentChatUser({
                page: currentPage.value,
                per_page: 5,
                search: search.value
            })

            //get response
            const response = await store.getAppointmentChatUserResponse

            //assign value
            if (response) {
                appointmentChatUser.value.push(...response.data.data)
                appointmentChatUser.value.sort(activateValueFirst)
                appointmentChatUser.value.map((chatUser) => {
                    try {
                        const messageRef = db.collection(chatCollectionName.value).doc(chatUser.id.toString()).collection('messages').orderBy('time', 'desc').limit(1)

                        messageRef.onSnapshot((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                                chatUser.last_message = doc.data()
                            })
                        })
                    } catch (e) {
                        console.error('Error reading document: ', e)
                    }

                    return chatUser
                })

                if (appointmentChatUser.value.length >= response.data.total) {
                    isLastPage.value = true
                } else {
                    isLastPage.value = false
                }
            }

            // isLoading.value = false
        }
    }

    // const changeTab = (e) => {
    //   console.log(e)
    //   isEmpty.value = true
    //   chatId.value = ""
    //   chatUserName.value = ""
    //   chatImageUrl.value = ""
    // }

    const filterSearch = () => {
        currentPage.value = 0
        appointmentChatUser.value = []
        isLastPage.value = false
        fetchAppointmentChatUser()
    }

    const onScroll = useDebounceFn((event) => {
        const scrollHeight = event.target.scrollHeight
        const scrollTop = event.target.scrollTop
        const clientHeight = event.target.clientHeight

        if (clientHeight <= Math.ceil(scrollTop) + 5 + scrollHeight) {
            fetchAppointmentChatUser()
        }
    }, 500)

    const isSender = (id) => {
        return id == user.id
    }

    const latestMessage = (message) => {
        if (isSender(message.sender_id)) {
            if (message.attachments.length > 0) {
                return message.attachments.length === 1 ? 'You sent a file' : `You sent ${message.attachments.length} files`
            } else {
                return `You: ${message.message}`
            }
        } else {
            if (message.attachments.length > 0) {
                return message.attachments.length === 1 ? 'You received a file' : `You received ${message.attachments.length} files`
            } else {
                return message.message
            }
        }
    }

    const isUnreadMessage = (message) => {
        const lastMessage = message.last_message
        if (lastMessage && lastMessage.read_by) {
            if (lastMessage.read_by.includes(user.id.toString())) {
                return false
            }
            return true
        }
        return false
    }

    const presenceBorderClass = (usr) => {
        if (usr.activate) {
            return 'border-primary'
        }
        return 'border-orange-500'
    }

    const count = computed(() => {
        let total = 0
        appointmentChatUser.value.forEach((usr) => {
            if (usr.last_message && usr.last_message.read_by) {
                if (!usr.last_message.read_by.includes(user.id.toString())) {
                    total++
                }
            }
        })
        return total
    })

    const closeChat = () => {
        localStorage.setItem('chatOpen', 0)
        window.location.reload()
    }

    watch(
        [search],
        useDebounceFn(() => {
            filterSearch()
        }, 500)
    )

    return {
        count,
        showSideBar,
        getUserList,
        chooseConversation,
        isEmpty,
        chatId,
        chatUserName,
        chatCollectionName,
        chatImageUrl,
        chatMateUserId,
        activeIndex,
        search,
        filterSearch,
        onScroll,
        isLoading,
        latestMessage,
        formatChatListTime,
        activate,
        isUnreadMessage,
        presenceBorderClass,
        closeChat

        // changeTab
    }
}

function activateValueFirst(a, b) {
    return a.activate === b.activate ? 0 : a.activate ? -1 : 1
}
