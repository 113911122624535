import { reactive, ref, onMounted, onBeforeUnmount } from 'vue'
import moment from 'moment'
import { useHealthArticleStore } from '@/modules/health_article/store'
import { useStore as useCategoryStore } from '@/modules/category/store'
import { useRouter } from 'vue-router'
import EventBus from '@/libs/AppEventBus'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { Errors } from '@/utils/server-validation'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'

export const useNewHealthArticle = () => {
    const contentStore = useHealthArticleStore()
    const categoryStore = useCategoryStore()
    const router = useRouter()
    const isLoading = ref(false)
    const submitted = ref(false)
    const errors = new Errors()
    const showCropDialog = ref(false)
    const crop_photo = ref(null)
    const photo = ref(null)
    const photoURL = ref('')
    const fileUrl = ref('')
    const optionsCategory = ref([])
    const imgErrorMessage = ref(null)
    const state = reactive({
        titleEn: '',
        titleMm: '',
        photo: '',
        title: '',
        filename: '',
        status: '',
        user_type: 'public',
        post_type: 'free',
        category_id: null,
        contentEn: '',
        contentMm: '',
        published: '',
        published_at: null,
        expired_at: '',
        is_notifiable: false,
        has_notified: false,
        video_url: ''
    })

    const formError = reactive({
        titleEn: '',
        titleMm: ''
    })

    const rules = {
        title: { required },
        titleEn: { required },
        titleMm: { required },
        post_type: { required },
        user_type: { required },
        expired_at: {},
        category_id: { required },
        photo: { required },
        filename: {},
        onlineOndemand: {},
        active: {},
        emr_speciality_id: { required }
    }

    const v$ = useVuelidate(rules, state)

    const optionsUserType = ref([
        { name: 'COPORATE', code: 'corporate' },
        { name: 'PUBLIC', code: 'public' }
    ])

    const optionsPostType = ref([
        { name: 'PREMIUM', code: 'premium' },
        { name: 'FREE', code: 'free' }
    ])

    const categoryData = async () => {
        isLoading.value = true

        try {
            await categoryStore.fetchAllContentCategory({
                page: 1,
                per_page: 'all',
                active: 1,
                type: 'health_news'
            })

            const response = categoryStore.listContentResponse

            if (response) {
                optionsCategory.value = JSON.parse(JSON.stringify(response.data))
            }

            isLoading.value = false
        } catch (error) {
            isLoading.value = false
            if (error.status === 422) {
                const err = error.data.data
                errors.record(err)
            }
        }
    }

    onMounted(() => {
        categoryData()
    })

    onBeforeUnmount(() => {
        categoryStore.$dispose()
        contentStore.$dispose()
    })

    const handleSubmit = (isFormValid) => {
        submitted.value = true

        if (!isFormValid || imgErrorMessage.value) {
            return
        }
        addContent()
    }

    const onUpload = (event) => {
        photoURL.value = event.files[0].objectURL
        state.photo = event.files[0]
        state.filename = event.files[0].filename

        if (state.photo) {
            const reader = new FileReader()
            reader.onload = (e) => {
                validateImageRatio(e.target.result)
            }
            reader.readAsDataURL(state.photo)
        }
    }

    const validateImageRatio = (image) => {
        const img = new window.Image()
        img.onload = () => {
            const width = img.width
            const height = img.height
            const desiredRatio = 2
            const ratio = parseInt(width / height)

            if (ratio !== desiredRatio) {
                imgErrorMessage.value = `The image ratio should be ${desiredRatio}:1. Please upload a valid image.`
            } else {
                imgErrorMessage.value = null
            }
        }
        img.src = image
    }

    const onClickPublish = () => {
        if (imgErrorMessage.value) {
            return
        }
        addContent('published')
    }

    const onClickDraft = () => {
        if (imgErrorMessage.value) {
            return
        }
        addContent('drafted')
    }

    const addContent = async (status = null) => {
        errors.clear()
        formError.titleEn = ''
        formError.titleMm = ''
        isLoading.value = true

        const content = JSON.stringify({
            en: state.contentEn,
            mm: state.contentMm
        })

        let title = JSON.stringify({
            en: state.titleEn,
            mm: state.titleMm
        })

        if (state.titleEn === '') {
            formError.titleEn = `title (english) is required`
            title = ''
        }

        if (state.titleMm === '') {
            formError.titleMm = `title (myanmar) is required`
            title = ''
        }

        var formData = new FormData()
        const date = new Date()

        if (state.photo) {
            formData.append('photo', state.photo)
        }

        switch (status) {
            case 'published':
                state.status = state.published_at > date ? 'pending' : 'published'
                break

            case 'drafted':
                state.status = 'drafted'
                break

            default:
                break
        }

        formData.append('title', title)
        formData.append('body', content)
        formData.append('category_id', state.category_id)
        formData.append('status', state.status)
        formData.append('user_type', state.user_type)
        formData.append('post_type', state.post_type)
        formData.append('published_at', state.published_at === null ? moment(date).format('YYYY-MM-DD') : moment(state.published_at).format('YYYY-MM-DD'))
        formData.append('expired_at', state.expired_at === null || state.expired_at === '' ? '' : moment(state.expired_at).format('YYYY-MM-DD'))
        formData.append('is_notifiable', state.is_notifiable ? 1 : 0)
        formData.append('has_notified', state.has_notified ? 1 : 0)
        formData.append('video_url', state.video_url)

        try {
            await contentStore.addContentAction(formData)

            const response = contentStore.getAddContent
            if (response) {
                router.push({ name: 'healthArticle' }).then(() => {
                    EventBus.emit('show-toast', {
                        severity: 'success',
                        summary: '',
                        detail: response.message
                    })
                })
            }

            isLoading.value = false
        } catch (error) {
            isLoading.value = false
            if (error.status === 422) {
                const err = error.data.data
                errors.record(err)
            }
        }
    }

    const takePicture = async () => {
        const cameraOptions = {
            quality: 90,
            allowEditing: true,
            resultType: CameraResultType.Uri,
            source: CameraSource.Photos,
            saveToGallery: false,
            width: 300,
            height: 300
        }

        const image = await Camera.getPhoto(cameraOptions)

        try {
            // image.webPath will contain a path that can be set as an image src.
            // You can access the original file using image.path, which can be
            // passed to the Filesystem API to read the raw data of the image,
            // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
            photo.value = image.webPath
            showCropDialog.value = true
        } catch (err) {
            console.log(err.message)
        }
    }

    const cropImage = async ({ canvas }) => {
        crop_photo.value = canvas.toDataURL('image/jpeg')

        canvas.toBlob(function (blob) {
            fileUrl.value = blob
        })
    }

    return {
        formError,
        isLoading,
        state,
        submitted,
        errors,
        onClickPublish,
        onClickDraft,
        optionsPostType,
        handleSubmit,
        optionsUserType,
        optionsCategory,
        crop_photo,
        onUpload,
        photoURL,
        v$,
        cropImage,
        showCropDialog,
        takePicture,
        photo,
        imgErrorMessage
    }
}
