<template>
    <div class="card mb-4">
        <div class="grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label class="label-sm">{{ $t('status') }}</label>
                {{ selectedStatus }}
                <Dropdown v-model="selectedStatus" :options="statuses" :filter="true" :showClear="true" optionLabel="name" optionValue="code" placeholder="Select status" class="w-full" />
            </div>

            <div class="field col-12 md:col-6 lg:col-3">
                <label class="label-sm">{{ $t('date') }}</label>
                <Calendar icon="ri-calendar-line ri-sm" :placeholder="$t('where_date')" inputId="dateBetween" v-model="dateBetween" selectionMode="range" :manualInput="false" dateFormat="dd/mm/yy" :showIcon="true" :showButtonBar="true" />
            </div>
            <div class="field col-12 md:col-6 lg:col-3 lg:col-offset-3">
                <label class="label-sm">{{ $t('reset') }}</label>
                <Button icon="ri-filter-off-line ri-lg" class="p-button-secondary p-button-outlined w-full" @click="resetSearch" />
            </div>
        </div>
    </div>
    <div class="card">
        <DataTable
            ref="dt"
            data-key="id"
            sortMode="multiple"
            paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            responsiveLayout="scroll"
            :lazy="true"
            :paginator="true"
            :value="contentList"
            :multiSortMeta="lazyParams.multiSortMeta"
            :totalRecords="totalRecords"
            :rows="5"
            :loading="loading"
            :rowsPerPageOptions="[5, 10, 20, 50, 100]"
            :scrollable="true"
            @page="onPage($event)"
            @sort="onSort($event)"
            class="p-datatable-md"
            scrollHeight="70vh"
        >
            <template #header>
                <div class="flex flex-wrap justify-content-between align-items-center">
                    <h5 class="m-0 md:mb-2 block lg:hidden">Health Articles</h5>

                    <div class="mt-3 md:mt-0 ml-0 lg:ml-auto">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <div class="mt-3 md:mt-0 ml-0 lg:ml-auto">
                                <span class="p-input-icon-left w-full md:w-auto">
                                    <i class="pi pi-search" />
                                    <InputText v-model="search" placeholder="Keyword Search" class="w-full md:w-auto p-inputtext-sm" />
                                </span>

                                <router-link :to="{ name: 'newHealthArticle' }" v-if="$can('create', 'health-article')">
                                    <Button :label="$t('new')" class="p-button-success ml-0 md:ml-2 mt-2 md:mt-0 p-button-sm" />
                                </router-link>
                                <Menu ref="menu" :model="actionItems" :popup="true" />
                                <Button icon="ri-settings-2-line ri-sm" @click="toggleMenu" class="p-button-secondary p-button-text ml-2 mt-2 md:mt-0 p-button-sm" />
                                <OverlayPanel ref="columnMenu" appendTo="body" id="columnPanel" style="width: 250px; padding: 10px !important">
                                    <div v-for="column in columns" :key="column.field" class="field-checkbox">
                                        <Checkbox inputClass="p-3" :inputId="column.field" name="column" :modelValue="column.selected" :binary="true" @change="column.selected = !column.selected" :disabled="column.frozen" />
                                        <label :for="column.field">{{ $t(column.header) }}</label>
                                    </div>
                                </OverlayPanel>
                                <Button icon="ri-equalizer-line ri-sm" @click="toggleColumnMenu" class="p-button-secondary p-button-outlined ml-2 mt-2 md:mt-0 p-button-sm" />
                            </div>
                        </span>
                    </div>
                </div>
            </template>
            <template #empty> No health article found. </template>
            <template #loading> Loading health article data. Please wait. </template>

            <Column v-for="column in selectedColumns" :key="column.field" :field="column.field" :header="column.header" :sortable="column.sortable" :frozen="!isMobile && column.frozen" :alignFrozen="column.alignFrozen" :style="column.style">
                <template v-if="column.field === 'title.en'" #body="{ data }">
                    <span v-if="data.title.en">
                        <TableLabel :label="data.title.en" :length="30"></TableLabel>
                    </span>
                </template>
                <template v-else-if="column.field === 'title.mm'" #body="{ data }">
                    <span v-if="data.title.mm">
                        <TableLabel :label="data.title.mm" :length="30"></TableLabel>
                    </span>
                </template>
                <template v-else-if="column.field === 'photo'" #body="{ data }">
                    <Image :src="data.photo" alt="Image" width="60" height="60" preview />
                </template>
                <template v-else-if="column.field === 'view_count'" #body="{ data }"> {{ numberFormat(data.view_count) }} </template>
                <template v-else-if="column.field === 'user.name'" #body="{ data }">
                    {{ data.user.name }}
                </template>
                <template v-else-if="column.field === 'post_type'" #body="{ data }">
                    {{ data.post_type }}
                </template>
                <template v-else-if="column.field === 'published_at'" #body="{ data }">
                    {{ data.published_at }}
                </template>

                <template v-else-if="column.field === 'status'" #body="{ data }">
                    <Badge :value="data.status" :style="data.severity"></Badge>
                </template>
                <template v-else-if="column.field === 'created_at'" #body="{ data }">
                    {{ dateLong(data.created_at) }}
                </template>
                <template v-else-if="column.field === 'action'" #body="{ data }">
                    <div class="flex">
                        <router-link :to="{ name: 'editHealthArticle', params: { id: data.id } }" v-if="$can('update', 'health-article')">
                            <Button type="button" icon="ri-pencil-line ri-sm" class="p-button mr-2 p-component p-button-icon-only custom-sm-button p-button-rounded p-button-warning p-button-outlined" />
                        </router-link>
                    </div>
                </template>
            </Column>

            <!-- <Column
        v-for="col of columns_arr"
        :field="col.field"
        :header="col.header"
        :key="col.field"
        :sortable="col.sortable"
        :style="`min-width: ${col.width !== 'default' ? col.width : 'initial'}`"
      /> -->
        </DataTable>

        <ConfirmDialog :showHeader="false">
            <template #message="slotProps">
                <div class="text-center w-full">
                    <Button type="button" :icon="slotProps.message.icon" class="p-button-lg p-button-danger p-button-rounded p-button-outlined mt-4" />
                    <h4>{{ slotProps.message.message }}</h4>
                </div>
            </template>
        </ConfirmDialog>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import Button from 'primevue/button'
import Column from 'primevue/column'
import Dropdown from 'primevue/dropdown'
import Badge from 'primevue/badge'
import Calendar from 'primevue/calendar'
import DataTable from 'primevue/datatable'
import InputText from 'primevue/inputtext'
import Image from '@/components/Image'
import Menu from 'primevue/menu'
import ConfirmDialog from 'primevue/confirmdialog'
import TableLabel from '@/components/TableLabel.vue'
import { useHealthArticleList } from '@/modules/health_article/list/useList'
import OverlayPanel from 'primevue/overlaypanel'
import Checkbox from 'primevue/checkbox'
import useDevice from '@/utils/device'
import { numberFormat } from '@/utils/formatter'

export default defineComponent({
    name: 'useHealthArticleList',
    components: {
        ConfirmDialog,
        DataTable,
        Menu,
        InputText,
        TableLabel,
        Calendar,
        Badge,
        Image,
        Column,
        Dropdown,
        Button,
        OverlayPanel,
        Checkbox
    },
    setup() {
        const { dt, lazyParams, selectedStatus, resetSearch, dateBetween, totalRecords, contentList, loading, statuses, search, menu, columns_arr, toggleMenu, onPage, actionItems, onSort, selectedColumns, columns, columnMenu, toggleColumnMenu } =
            useHealthArticleList()
        const { isMobile } = useDevice()
        return {
            dt,
            menu,
            resetSearch,
            toggleMenu,
            actionItems,
            dateBetween,
            selectedStatus,
            statuses,
            lazyParams,
            totalRecords,
            contentList,
            loading,
            search,
            columns_arr,
            onPage,
            onSort,
            selectedColumns,
            columns,
            columnMenu,
            toggleColumnMenu,
            isMobile,
            numberFormat
        }
    }
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/custom-table.scss';
</style>
